<template>
  <div class="mt-8">
    <div id="modal__gallery" class="modal" v-show="isShowingModal">
      <div class="modal__content">
        <span class="modal__close" @click="closeModal">&times;</span>
        <div class="modal__body" tabindex="0" @focusout="closeModal">
          <img :src="modalImage" class="show__gallery" />
        </div>
      </div>
    </div>
    <main class="main">
      <div class="container">
        <div class="product mb-9">
          <div class="product__header">
            <div class="product__row">
              <div class="prodcut__gallery">
                <div class="gallery">
                  <div class="gallery__slideshow">
                    <div class="gallery__slides">
                      <div class="gallery__slide">
                        <img
                          class="gallery__img"
                          :src=" product?.product?.photo ? product?.product?.photo : require('@/assets/img/thumb.jpg')"
                          :alt="product?.product?.name"
                          style="display:block"
                          @click="showModal(product?.product?.photo)"
                        />
                      </div>
                    </div>
                    <a v-if="false" @click.prevent="move(-1)" class="gallery__prev"
                      >&#10095;</a
                    >
                    <a v-if="false" @click.prevent="move(1)" class="gallery__next"
                      >&#10094;</a
                    >
                  </div>
                  <div class="gallery__content">
                    <div class="gallery__items">
                      <div
                        class="gallery__item"
                        :class="{
                          'gallery__item--is-acitve': slideIndex === index
                        }"
                        v-for="(slide, index) in gallerySlides"
                        :key="`item-${index}`"
                      >
                        <img
                          :src="slide.img"
                          @click="currentSlide(index)"
                          class="gallery__item-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product__left pt-5">
                <div class="w-full sm:w-96 md:w-8/12 lg:w-6/12 items-center">
                  <p class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 font-normal text-base leading-4 text-gray-600  dark:text-white">
                    <router-link :to="{ name: 'Home' }" class="inline-block no-underline hover:text-black">Kryefaqja</router-link><span v-if="product?.product?.category">/</span> {{product?.product?.category}} / {{product?.product?.name}}
                  </p>
                  <h2 class="font-semibold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 dark:text-white mt-4">{{product?.product?.name}}</h2>

                  <div class="font-normal text-base leading-6 text-gray-600  mt-7" v-html="product?.product?.description"></div>
                  <p class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 mt-6 dark:text-white">{{$filters.currency(product?.product?.sale_price)}}</p>

                  <div class="lg:mt-11 mt-10">
                    <hr class="bg-gray-200 w-full my-2" />
                      <p class="text-base leading-4 mt-7 mb-5 text-gray-600 dark:text-gray-300" v-if="product?.product?.sku">SKU: {{product?.product?.sku}}</p>
                       <hr class="bg-gray-200 w-full my-2" v-if="product?.product?.sku" />
                      <p class="text-base leading-4 mt-7 text-gray-600 dark:text-gray-300" v-if="product?.product?.weight">Pesha: {{product?.product?.weight}}</p>
                  </div>

                  <button @click="addItem(product.product)" class="focus:outline-none focus:ring-2 hover:bg-black focus:ring-offset-2 focus:ring-gray-800 font-medium text-base leading-4 text-white bg-gray-800 w-full py-5 lg:mt-12 mt-6 dark:bg-white dark:text-gray-900 dark:hover:bg-gray-100">Shto në shport</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SwiperSlider v-if="product?.products?.length">
          <template v-slot:title> Shiko gjithashtu </template>
          <router-link
            :to="{ name: 'Product', params: { slug: item.slug } }"
            class="swiper-slide"
            v-for="item in product?.products"
            :key="item.id"
          >
            <div class="card">
              <div class="card__image">
                <img
                  :src="item.photo"
                  alt=""
                  class="card__img"
                />
              </div>
              <div class="card__title2">{{ item.name }}</div>
              <div class="card__price">
                <span class="card__total-price">{{$filters.currency(item.sale_price)}}</span>
              </div>
              <span v-if="item.discount" class="card__discount">%{{item.discount}}</span>
            </div>
          </router-link>
        </SwiperSlider>
      </div>
    </main>
  </div>
</template>

<script>
import SwiperSlider from '../components/SwiperSlider'
import '../assets/css/modal.css'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'Product',

  components: {
    SwiperSlider,
  },

  data() {
    return {
      counter:1,
      gallerySlides: [],
      slideIndex: 0,
      isShowingModal: false,
      modalImage: null,
      slides: []
    }
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
    this.$store.dispatch('products/getProductBySlug', { slug: this.$route.params.slug })
  },
  computed: {
    ...mapState('cart', ['items']),
    ...mapGetters('products', ['product'])
  },
  watch:{
    '$route.params.search': {
      handler: function(search) {
        this.$store.dispatch('products/getProductBySlug', { slug: this.$route.params.slug })
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    move(n) {
      if (this.gallerySlides.length <= this.slideIndex + n) {
        this.slideIndex = 0
      } else if (this.slideIndex + n < 0) {
        this.slideIndex = this.gallerySlides.length - 1
      } else {
        this.slideIndex += n
      }
    },
    currentSlide(index) {
      this.slideIndex = index
    },
    showModal(image) {
      this.isShowingModal = true
      this.modalImage = image
      document.body.style.overflow = 'hidden'
    },
    closeModal() {
      this.isShowingModal = false
      document.body.style.overflow = 'unset'
    },

    ...mapActions('cart', ['addItem'])
  },

}
</script>
